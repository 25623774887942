import React from 'react'
import './about.css'
import ME from '../../assets/leobmw.jpg'
import {LuGraduationCap} from 'react-icons/lu'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="about image"/>
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <LuGraduationCap className='about__icon'/>
              <h5>Education</h5>
              <small>2018-2022 Liberty High School</small><div/>
              <small>2022-Present Gonzaga University</small>
            </article>
          </div>  
          <p>
            I'm a computer science student from Seattle, Washington <div/> currently studying at Gonzaga University.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About