import React from 'react'
import './experience.css'
import {BsCheckCircle} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Technical Levels</h5>
      <h2>Skills</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend</h3>
          <div className="experience__contetn">
            <article className='experience__details'>
              <BsCheckCircle className='experience__details-icon'/>
              <h4>HTML</h4>
              <small className='text-light'>Intermediate</small>
            </article>
            <article className='experience__details'>
              <BsCheckCircle className='experience__details-icon'/>
              <h4>CSS</h4>
              <small className='text-light'>Intermediate</small>
            </article>
            <article className='experience__details'>
              <BsCheckCircle className='experience__details-icon'/>
              <h4>React</h4>
              <small className='text-light'>Basic</small>
            </article>
          </div>
        </div>
        <div className='experience__backend'>
          <h3>Backend</h3>
          <article className='experience__details'>
              <BsCheckCircle className='experience__details-icon'/>
              <h4>C++</h4>
              <small className='text-light'>Advanced</small>
            </article>
            <article className='experience__details'>
              <BsCheckCircle className='experience__details-icon'/>
              <h4>Java</h4>
              <small className='text-light'>Intermediate</small>
            </article>
            <article className='experience__details'>
              <BsCheckCircle className='experience__details-icon'/>
              <h4>React</h4>
              <small className='text-light'>Basic</small>
            </article>
        </div>
      </div>
    </section>
  )
}

export default Experience