import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {AiOutlineInstagram} from 'react-icons/ai'

const headerSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/leonardjia/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/leojia22" target="_blank"><BsGithub/></a>
        <a href="https://www.instagram.com/leojia22/" target="_blank"><AiOutlineInstagram/></a>
    </div>
  )
}

export default headerSocials