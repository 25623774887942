import React from 'react'
import './skills.css'
import IMG1 from '../../assets/portfoliowebsitepicture.png'

const Skills = () => {
  return (
    <section id='skills'>
      <h5>Some Projects I Have Done</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className="portfolio__item">
            <img src={IMG1} alt="" />
          <div className="portfolio__item-image"></div>
            <h3>Personal Portfolio Website</h3>
            <a href="leonardjia.com" className='btn'>Link</a>
        </article>
      </div>
    </section>
  )
  
}

export default Skills